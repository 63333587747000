<template>
  <div>
    <LocationWidget
      :widgetDetails="widgetDetails"
      :data="data"
      :chartConfigurations="chartConfigurations"
      :widgetTblConfigurations="widgetTblConfigurations"
      :formSubmitted="formSubmitted"
      :isEditPage="isEditPage"
    />
    <b-form-row>
      <b-col>
        <b-form-group
          :class="{
            'is-invalid': !data.unlinkedWidgetTxt && formSubmitted,
          }"
        >
          <p>Unlinked widget text</p>
          <editor
            :api-key="tinyEditorOptions.apiKey"
            :init="tinyEditorCustomHeightOptions"
            v-model="data.unlinkedWidgetTxt"
          />
          <b-form-invalid-feedback
            class="d-block"
            v-if="!data.unlinkedWidgetTxt && formSubmitted"
            >Unlinked widget text required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group
          :class="{
            'is-invalid': !data.linkAccountTxt && formSubmitted,
          }"
        >
          <p>Text for linking account</p>
          <editor
            :api-key="tinyEditorOptions.apiKey"
            :init="tinyEditorCustomHeightOptions"
            v-model="data.linkAccountTxt"
          />
          <b-form-invalid-feedback
            class="d-block"
            v-if="!data.linkAccountTxt && formSubmitted"
            >Text for linking account required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group
          :class="{
            'is-invalid': !data.unLinkAccountTxt && formSubmitted,
          }"
        >
          <p>Text for un-linking account</p>
          <editor
            :api-key="tinyEditorOptions.apiKey"
            :init="tinyEditorCustomHeightOptions"
            height="100"
            v-model="data.unLinkAccountTxt"
          />
          <b-form-invalid-feedback
            class="d-block"
            v-if="!data.unLinkAccountTxt && formSubmitted"
            >Text for un-linking account required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import { TinyEditorOptions } from '../../../utilities/constants'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'IntegrationWidget',
  props: [
    'widgetDetails',
    'data',
    'tenantId',
    'formSubmitted',
    'isEditPage',
    'chartConfigurations',
    'widgetTblConfigurations'
  ],
  components: {
    editor: Editor,
    LocationWidget: () => import('./LocationWidget.vue')
  },
  data () {
    return {
      tinyEditorOptions: TinyEditorOptions
    }
  },
  computed: {
    tinyEditorCustomHeightOptions () {
      const config = cloneDeep(this.tinyEditorOptions.initConfig)
      config.height = 200
      return config
    }
  }
}
</script>
