var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LocationWidget',{attrs:{"widgetDetails":_vm.widgetDetails,"data":_vm.data,"chartConfigurations":_vm.chartConfigurations,"widgetTblConfigurations":_vm.widgetTblConfigurations,"formSubmitted":_vm.formSubmitted,"isEditPage":_vm.isEditPage}}),_c('b-form-row',[_c('b-col',[_c('b-form-group',{class:{
          'is-invalid': !_vm.data.unlinkedWidgetTxt && _vm.formSubmitted,
        }},[_c('p',[_vm._v("Unlinked widget text")]),_c('editor',{attrs:{"api-key":_vm.tinyEditorOptions.apiKey,"init":_vm.tinyEditorCustomHeightOptions},model:{value:(_vm.data.unlinkedWidgetTxt),callback:function ($$v) {_vm.$set(_vm.data, "unlinkedWidgetTxt", $$v)},expression:"data.unlinkedWidgetTxt"}}),(!_vm.data.unlinkedWidgetTxt && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Unlinked widget text required.")]):_vm._e()],1)],1)],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',{class:{
          'is-invalid': !_vm.data.linkAccountTxt && _vm.formSubmitted,
        }},[_c('p',[_vm._v("Text for linking account")]),_c('editor',{attrs:{"api-key":_vm.tinyEditorOptions.apiKey,"init":_vm.tinyEditorCustomHeightOptions},model:{value:(_vm.data.linkAccountTxt),callback:function ($$v) {_vm.$set(_vm.data, "linkAccountTxt", $$v)},expression:"data.linkAccountTxt"}}),(!_vm.data.linkAccountTxt && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Text for linking account required.")]):_vm._e()],1)],1)],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',{class:{
          'is-invalid': !_vm.data.unLinkAccountTxt && _vm.formSubmitted,
        }},[_c('p',[_vm._v("Text for un-linking account")]),_c('editor',{attrs:{"api-key":_vm.tinyEditorOptions.apiKey,"init":_vm.tinyEditorCustomHeightOptions,"height":"100"},model:{value:(_vm.data.unLinkAccountTxt),callback:function ($$v) {_vm.$set(_vm.data, "unLinkAccountTxt", $$v)},expression:"data.unLinkAccountTxt"}}),(!_vm.data.unLinkAccountTxt && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Text for un-linking account required.")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }